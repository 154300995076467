.footer {
    background-color: black;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    align-items: center;
    padding: 1rem 2rem; 
    color: white;
    font-size: 1.5rem; 
    flex-wrap: 1;
}

.footer h1{
    color: #8b4513;
}

.futbutton {
    background-color: wheat;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
}

.futbutton a {
    text-decoration: none;
    color: black;
}
  
.futbutton:hover {
    background-color: burlywood;
}

.loggg {
    font-size: small;
    color: #8b4513;
}
  