/* admin.css */

/* Styling for the main container */
.admin-container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
}

/* Styling for the left box containing categories */
.left-box {
  width: 400px;
  overflow-y: auto; /* Enable vertical scrolling */
  background-color: #f0f0f0; /* Light background color */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.left-box h2 {
  margin-bottom: 10px;
}

.left-box ul {
  list-style: none;
  padding: 0;
}

.left-box li {
  margin-bottom: 10px;
  background-color: white; /* Category item background color */
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  cursor: pointer; /* Change cursor to pointer */
}

.left-box li button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none; /* Remove button outline */
}

.left-box li button img {
  width: 20px;
  height: 20px;
}

/* Styling for the admin panel containing main content */
.admin-panel {
  flex: 1; /* Occupy remaining space */
  margin-left: 20px; /* Adjust spacing between columns */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9; /* Light background color */
  overflow-y: auto; /* Enable vertical scrolling */
}

.admin-panel h2 {
  margin-bottom: 10px;
}

.menu-list {
  display: flex;
  flex-wrap: wrap; 
  flex-direction: column;
  gap: 20px;
}

.menu-item {
  display: flex;/* Each item takes full width */
  background-color: white; /* Item background color */
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  min-width: 250px; /* Minimum item width */
  gap: 1rem;
  justify-content: space-between;
}

.menu-item > div {
  margin-bottom: 10px;
}

/* Styling for the menu item images */
.menu-item-image {
  width: 250px;
  max-height: 250px;
  object-fit: cover;
  border-radius: 5px;
}

.butlist {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.butlist button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.butlist button img {
  width: 20px;
  height: 20px;
}

/* Styling for the add button */
.add-button {
  display: flex;
  justify-content: flex-end; /* Align button to the right */
  margin-top: 20px; /* Space between menu and button */
}

.add-button button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff; /* Button color */
  color: white; /* Text color */
  font-size: 16px;
  cursor: pointer;
}

.add-button button:hover {
  background-color: #0056b3; /* Hover color */
}

/* Styling for the footer */
.admin-header {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ancrem {
  text-decoration: none;
}

/* Additional styling if needed */

.category-buttons-container {
  display: flex;
  flex-direction: row;
}

.category-buttons {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #8b4513; 
  color: white;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Segoe UI', sans-serif; 
  width: 80%;
}

.category-buttons:hover {
  background-color: red;

}

.delete-button {
  background-color: grey;

}

.delete-button:hover {
  background-color: red;

}

.return-menu {
  display: flex;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  margin: 20px auto;
  padding: 20px;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.return-menu a {
  text-decoration: none;
  color: black;
  align-items: center;
}