.prettylist {
    list-style-type: none;
}

.prettylist li {
    display: flex;
    flex-direction: row;
}

.menu-item {
    display: flex;/* Each item takes full width */
    background-color: white; /* Item background color */
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    min-width: 250px; /* Minimum item width */
    gap: 1rem;
    justify-content: space-between;
  }

  .change-pic {
    background-color: wheat;
    border: 2px solid brown;
    color: black;
  }

  .change-pic:hover {
    background-color: brown;
    border: 2px solid brown;
    color: wheat;
  }