* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    position: relative;
    min-height: 100vh;
    background-color: #8b4513;

}