.strip {
    height: 150px;
    background-color: #ccc;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 4rem;
    color: white;
    padding: 3rem;

  }
  
.logoo {
  padding: 0.25rem;
}