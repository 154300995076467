/* Updated body.css with wood-themed styles */

/* Main container styles */
.main-box {
  margin: 20px;
  padding: 20px;
  background-color: #f5deb3; /* Light brown background */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.invitation {
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Segoe UI', sans-serif; /* Use appropriate font */
}

.category-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.headbutton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #8b4513; /* Brown button */
  color: white;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Segoe UI', sans-serif; /* Use appropriate font */
}

.headbutton.active {
  background-color: #6b3f12; /* Darker brown for active button */
}

.solid-line {
  border-top: 1px solid #8b4513; /* Matching border color */
  margin-bottom: 20px;
}

.selected-category {
  text-align: center;
  margin-bottom: 20px;
}

.menu-line {
  width: 50px;
  height: 2px;
  background-color: #8b4513; 
  margin: 10px auto;
}

.menu-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row;
}

.item-details {
  flex: 1;
}

.item-name {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #6b3f12; /* Dark brown text */
}

.item-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6b3f12; /* Dark brown text */
}

.item-description {
  margin-top: 10px;
  color: #888;
}

.disclaimer {
  text-align: center;
  margin-top: 20px;
  color: #777;
  font-style: italic;
}

/* Additional styles for wooden vibe */


.item-image img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
  border: 2px solid #6b3f12; /* Dark brown border for images */
}
