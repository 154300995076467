.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 65vh;
    background-color: wheat;
  }
  
  .login-page {
    max-width: 400px;
    width: 90%; /* Adjust width to take up more space */
    padding: 20px;
    margin: auto; /* Center horizontally */
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white; /* Change background color */
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="email"],
  input[type="password"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .logbutton {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .logbutton:hover {
    background-color: #0056b3;
  }
  