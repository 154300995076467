.add-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  gap: 2rem;
  flex-direction: row;
  background-color: wheat;
}

.upload-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  background-color: white;
  height: 500px;
  width: 500px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow */
}

.upload-label {
  margin-bottom: 10px;
}

.upload-input {
  display: none;
}

.image-preview {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.add-item-form {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  background-color: #f9f9f9;
  width: 600px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  flex-direction: column;
  text-align: center;
}

.add-item-form h2 {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
  font-size: 16px;
  margin-top: 5px;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button:hover {
  background-color: #0056b3;
}

.return-button-container {
  display: flex;
  justify-content: left;
  margin-top: 20px; 
  height: 300px;
  width: 300px;
}

.return-button {
  background-color: burlywood;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none; /* Remove underline from anchor tag */
}

.return-button:hover {
  background-color: brown;
}
